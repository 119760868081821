import { useCallback } from 'react';

import qs from 'qs';

import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';
import { useCurrentUser, useSessionUuid } from 'src/app/shared/hooks/session';
import type { ShareActionsOptions } from 'src/app/shared/hooks/share';

import type { Channel } from './types';

export function useGetShareUrl(): ShareActionsOptions<Channel>['getUrl'] {
	const {
		data: {
			update: {
				id,
				petition: { slug },
			},
		},
	} = usePetitionUpdatePageData();
	const user = useCurrentUser();
	const sessionUuid = useSessionUuid();

	return useCallback(
		(medium) => {
			const trackParams = qs.stringify(
				{
					recruiter: user?.id || undefined,
					recruited_by_id: sessionUuid,
					utm_source: 'share_update',
					utm_campaign: `share_${medium}_responsive`,
					utm_medium: medium,
				},
				{ encodeValuesOnly: true },
			);
			return `/p/${encodeURIComponent(slug)}/u/${id}?${trackParams}`;
		},
		[slug, id, sessionUuid, user],
	);
}
