import { createFcmContext } from 'src/app/shared/contexts/fcm';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: PetitionUpdatePageFcmContext,
	FcmProvider: PetitionUpdatePageFcmContextProvider,
	useFcm: usePetitionUpdatePageFcm,
	useAsyncFcm: useAsyncPetitionUpdatePageFcm,
	prefetchFcm: prefetchPetitionUpdatePageFcm,
} = createFcmContext({
	name: 'PetitionUpdatePageFcmContext',
	dataProperty: 'pageFcm',
	configs: {},
	prefetch: true,
});

export type PetitionUpdatePageFcmState = ReturnType<typeof useAsyncPetitionUpdatePageFcm>;
export type PetitionUpdatePageFcm = LoadedStateData<PetitionUpdatePageFcmState>;
