import { useCallback } from 'react';

import { useQueryParamValue } from 'src/app/shared/hooks/location';
import type { ShareActionsOptions } from 'src/app/shared/hooks/share';

import type { Channel } from './types';

export function useGetShareTrackingData(): ShareActionsOptions<Channel>['getTrackingData'] {
	const sourceLocation = useQueryParamValue('source_location');

	return useCallback(
		(channel) => ({
			location: channel,
			view_context: 'petitions_updates_show',
			source_location: sourceLocation,
		}),
		[sourceLocation],
	);
}
