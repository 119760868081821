import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';

type Sibling = {
	title: string;
	url: string;
};

type Result = ModelHookResult<{
	previous?: Sibling;
	next?: Sibling;
}>;

export function useSiblingsNavigation(): Result {
	const {
		data: {
			update: {
				petition: { slug },
				siblings,
			},
		},
	} = usePetitionUpdatePageData();

	return {
		data: {
			previous: siblings.previous
				? { url: `/p/${encodeURIComponent(slug)}/u/${siblings.previous.id}`, title: siblings.previous.title }
				: undefined,
			next: siblings.next
				? { url: `/p/${encodeURIComponent(slug)}/u/${siblings.next.id}`, title: siblings.next.title }
				: undefined,
		},
	};
}
