import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';

type Result = ModelHookResult<{
	respondUrl?: string;
	signUrl?: string;
	promoteUrl?: string;
}>;

export function useActions(): Result {
	const {
		data: {
			petitionSigned,
			update: {
				petition: { promotable, slug, viewerIsDecisionMaker, status },
			},
		},
	} = usePetitionUpdatePageData();

	const open = status === 'PUBLISHED';

	return {
		data: {
			respondUrl: viewerIsDecisionMaker ? `/p/${encodeURIComponent(slug)}/responses/new` : undefined,
			signUrl:
				open && !petitionSigned && !viewerIsDecisionMaker
					? `/p/${encodeURIComponent(slug)}?show_sign=true&source_location=petition_update_page`
					: undefined,
			promoteUrl:
				open && petitionSigned && promotable && !viewerIsDecisionMaker
					? `/p/${encodeURIComponent(slug)}/psf/promote_or_share?source_location=petition_update_page`
					: undefined,
		},
	};
}
