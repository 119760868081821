import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';
import { Container } from '@change/design-system/layout';

import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';
import { MembershipAwareness } from 'src/app/shared/components/membershipAwareness';

import { PageBanner } from '../components/PageBanner';
import { PageBody } from '../components/PageBody';
import { PageFooter } from '../components/PageFooter';
import { PageHeading } from '../components/PageHeading';
import { PageMeta } from '../components/PageMeta';

import { useTrackPageView } from './hooks/useTrackPageView';

export const Layout = withPrefetchedData(
	function Layout(): JSX.Element {
		useTrackPageView();
		const {
			data: {
				update: {
					petition: { id: petitionId, slug },
				},
			},
		} = usePetitionUpdatePageData();

		return (
			<>
				<PageBanner />
				<Container variant="small" mt={16} pb={16}>
					<PageMeta />
					<PageHeading px={[16, 8]} />
					<PageBody mt={32} mb={24} px={[16, 8]} />
					<MembershipAwareness sourceLocation="petition_update" petitionId={petitionId} slug={slug} />
				</Container>
				<PageFooter pb={32} />
			</>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [MembershipAwareness],
	},
);
