import { useMemo } from 'react';

import { localeMappings } from '@change/config/legacy/locales'; // TODO move locales mapping to @change/config/facebook
import type { Locale } from '@change/config/locales';
import { stripHtmlTags } from '@change/core/html';
import { useI18n } from '@change/core/react/i18n';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';

type Result = ModelHookResult<{
	pageTitle: string;
	title: string;
	description: string;
	fbLocale: string;
	photo?: {
		url: string;
		width: number;
		height: number;
	};
}>;

function useUpdatePhoto() {
	const {
		data: { update },
	} = usePetitionUpdatePageData();
	const { resolveUrl } = useUtilityContext();

	return useMemo(() => {
		if (update.__typename === 'FeaturedNewsPetitionUpdate') {
			const { photo } = update.newsSource;
			if (photo) {
				return {
					url: resolveUrl(photo.url),
					width: photo.width,
					height: photo.height,
				};
			}
		} else {
			const { photo, embeddedMedia } = update;
			if (photo?.petitionLarge) {
				return {
					url: resolveUrl(photo.petitionLarge.url),
					width: photo.petitionLarge.width,
					height: photo.petitionLarge.height,
				};
			}
			const { thumbnailUrl, thumbnailWidth, thumbnailHeight } = embeddedMedia || {};
			if (thumbnailUrl && thumbnailWidth && thumbnailHeight) {
				return {
					url: resolveUrl(thumbnailUrl),
					width: thumbnailWidth,
					height: thumbnailHeight,
				};
			}
		}
		return undefined;
	}, [update, resolveUrl]);
}

export function usePageMeta(): Result {
	const {
		data: {
			update: {
				title,
				description,
				petition: { photo: petitionPhoto, originalLocale },
			},
		},
	} = usePetitionUpdatePageData();
	const { translate } = useI18n();
	const { resolveUrl } = useUtilityContext();

	const updatePhoto = useUpdatePhoto();

	return {
		data: {
			pageTitle: [translate('fe.pages.petition_update.title'), title, 'Change.org'].join(' · '),
			title,
			description: stripHtmlTags(description).substring(0, 400),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			fbLocale: localeMappings[originalLocale.localeCode as Locale]?.facebook || localeMappings['en-US']?.facebook,
			photo: useMemo(() => {
				if (updatePhoto) return updatePhoto;

				if (petitionPhoto?.petitionLarge) {
					return {
						url: resolveUrl(petitionPhoto.petitionLarge.url),
						width: petitionPhoto.petitionLarge.width,
						height: petitionPhoto.petitionLarge.height,
					};
				}

				return undefined;
			}, [petitionPhoto, updatePhoto, resolveUrl]),
		},
	};
}
