import type { ComponentProps } from 'react';

import { Waypoint } from 'react-waypoint';

import { Separator } from '@change/design-system/components/content';
import { Box, Container } from '@change/design-system/layout';

import { SuggestedPetitionsLazy } from 'src/app/shared/components/suggestedPetitionsLazy';

import { SiblingsNavigation } from './components/SiblingsNavigation';
import { usePageFooter } from './hooks/usePageFooter';

export function PageFooter(props: ComponentProps<typeof Box>) {
	const {
		data: { petitionId },
		actions: { handleSuggestedPetitionsPosition },
	} = usePageFooter();

	return (
		<Box {...props}>
			<Container variant="small">
				<SiblingsNavigation px={8} mb={32} />
				<Separator role="presentation" my={24} />
			</Container>
			<Waypoint
				onPositionChange={({ currentPosition }) => handleSuggestedPetitionsPosition(currentPosition === 'below')}
			/>
			<SuggestedPetitionsLazy channel="update_footer" sourceLocation="update_footer" petitionSlugOrId={petitionId} />
		</Box>
	);
}
