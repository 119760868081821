import type { ShareAction } from 'src/app/shared/hooks/share';
import { useAvailableShareActions } from 'src/app/shared/hooks/share';

import type { Channel } from './types';
import { CHANNELS } from './types';
import { useShareOptions } from './useShareOptions';

type Result = ModelHookResult<{
	shareActions: ReadonlyArray<ShareAction<Channel>>;
}>;

export function useShareLinks(): Result {
	const options = useShareOptions();
	return {
		data: {
			shareActions: useAvailableShareActions(CHANNELS, options),
		},
	};
}

export type { Channel };
