import {
	usePetitionUpdatePageData,
	usePetitionUpdatePageState,
} from 'src/app/pages/petitionUpdate/details/pageContext';

type Result = ModelHookResult<
	{
		petitionId: string;
	},
	{
		handleSuggestedPetitionsPosition: (below: boolean) => void;
	}
>;

export function usePageFooter(): Result {
	const {
		data: {
			update: {
				petition: { id: petitionId },
			},
		},
	} = usePetitionUpdatePageData();
	const [, { toggleBeforeSuggestedPetitions }] = usePetitionUpdatePageState();

	return {
		data: {
			petitionId,
		},
		actions: {
			handleSuggestedPetitionsPosition: (below) => {
				// if suggested petitions are below, we are before them
				toggleBeforeSuggestedPetitions(below);
			},
		},
	};
}
