import type { ComponentProps } from 'react';

import { Icon } from '@change/design-system/components/icon';
import { Avatar } from '@change/design-system/components/media';
import { iconChangeOrg } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { usePetitionUpdateByLine } from './hooks/usePetitionUpdateByLine';
import type { PetitionUpdateAuthor } from './hooks/usePetitionUpdateByLine';

// using this trick to avoid clipping of the first letter (due to Noto-Sans' design)
const clippingFixStyles = {
	paddingLeft: 8,
	marginLeft: -8,
};

export function PetitionUpdateByLine(props: ComponentProps<typeof Box>) {
	const {
		data: { author },
	} = usePetitionUpdateByLine();

	return (
		<Flex sx={{ alignItems: 'center' }} {...props}>
			<PetitionUpdateByLineAvatar author={author} />
			<Box sx={{ flex: 1 }}>
				<Text
					as="div"
					color="typography-primary"
					size="small"
					sx={{
						fontWeight: 'bold',
						...clippingFixStyles,
					}}
					ellipsis
				>
					{author.displayName}
				</Text>
				{author.location && (
					<Text as="div" color="typography-primary" size="small" sx={clippingFixStyles} ellipsis>
						{author.location}
					</Text>
				)}
			</Box>
		</Flex>
	);
}

const AVATAR_MARGIN = 12;
const AVATAR_SIZE = 40;

function PetitionUpdateByLineAvatar({ author }: { author: PetitionUpdateAuthor }) {
	if (author.type === 'news') {
		return null;
	}
	if (author.type === 'staff') {
		return <Icon size={AVATAR_SIZE} icon={iconChangeOrg} mr={AVATAR_MARGIN} color="primary-changeRed" />;
	}
	return <Avatar variant="subtle" src={author.photoUrl} alt="" size={AVATAR_SIZE} mr={AVATAR_MARGIN} />;
}
