import { useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';

import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';

type Result = ModelHookResult<{
	title: string;
	type: string;
	petitionTitle: string;
	petitionUrl: string;
	pageError?: string;
}>;

export function usePageHeading(): Result {
	const {
		data: { update },
	} = usePetitionUpdatePageData();
	const { translate } = useI18n();

	const { title, petition } = update;

	const type = useMemo(() => {
		if (update.__typename === 'FeaturedNewsPetitionUpdate') {
			// TODO handle batchable_media_mention too?
			return translate('fe.components.updates_feed.media_mention.title');
		}
		if (update.__typename === 'StaffPetitionUpdate') {
			return translate('fe.components.updates_feed.staff_update_title');
		}
		return translate('fe.pages.petition_update.title');
	}, [update, translate]);

	return {
		data: {
			title,
			type,
			petitionTitle: petition.ask,
			petitionUrl: `/p/${encodeURIComponent(petition.slug)}`,
		},
	};
}
