import type { ComponentProps } from 'react';

import { Link } from '@change/design-system/components/actions';
import { Box } from '@change/design-system/layout';
import { Heading, Text } from '@change/design-system/typography';

import { PetitionUpdateActions } from './components/PetitionUpdateActions';
import { PetitionUpdateByLine } from './components/PetitionUpdateByLine';
import { PetitionUpdateMedia } from './components/PetitionUpdateMedia';
import { usePageHeading } from './hooks/usePageHeading';

export function PageHeading(props: ComponentProps<typeof Box>) {
	const {
		data: { title, type, petitionTitle, petitionUrl },
	} = usePageHeading();

	return (
		<Box {...props}>
			<Text size="small" fontWeight="bold" color="typography-secondary" data-qa="petition-update-type">
				{type}
			</Text>
			<Box pt={8}>
				<Link to={petitionUrl} data-qa="petition-title-link">
					{petitionTitle}
				</Link>
			</Box>
			<Heading size={['h2', 'h1']} data-qa="petition-update-title" mt={16} breakWord>
				{title}
			</Heading>
			<PetitionUpdateActions mt={16} />
			<PetitionUpdateByLine mt={32} mb={32} />
			<PetitionUpdateMedia />
		</Box>
	);
}
