import type { ComponentProps } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Button, ButtonLink } from '@change/design-system/components/actions';
import { Box } from '@change/design-system/layout';

import { usePetitionUpdateActions } from './hooks/usePetitionUpdateActions';

export function PetitionUpdateActions(props: ComponentProps<typeof Box>) {
	const {
		data: { canEdit, editUrl },
		actions: { deletePetitionUpdate },
	} = usePetitionUpdateActions();

	if (!canEdit) return null;

	return (
		<Box {...props}>
			<ButtonLink variant="secondary" size="small" to={editUrl} mr={8} data-qa="edit-update-button">
				<Translate value="fe.pages.petition_update.edit" />
			</ButtonLink>
			<Button
				variant="secondary"
				size="small"
				onClick={deletePetitionUpdate}
				data-testid="delete-petition-update"
				data-qa="delete-update-button"
			>
				<Translate value="fe.pages.petition_update.delete" />
			</Button>
		</Box>
	);
}
