import type { ComponentType, JSX, PropsWithChildren } from 'react';

import { TrackingContext } from '@change/core/react/tracking';

import type { ErrorStateDetails } from 'src/app/shared/utils/async';
import { isError, isLoaded } from 'src/app/shared/utils/async';

import type { PetitionUpdatePageCombinedState, PetitionUpdatePagePrefetchedContext } from './hook';
import { usePageContext } from './hook';
import { PetitionUpdatePageDataContextProvider } from './pageData';
import { PetitionUpdatePageFcmContextProvider } from './pageFcm';
import { PetitionUpdatePageFcmExperimentsContextProvider } from './pageFcmExperiments';
import { PetitionUpdatePageStateContextProvider } from './pageState';

export type ErrorHandlerProps = { context: ErrorStateDetails<PetitionUpdatePageCombinedState> };

type Props = {
	petitionSlugOrId: string;
	updateId: string;
	prefetchedData: PetitionUpdatePagePrefetchedContext | undefined;
	errorHandler: ComponentType<ErrorHandlerProps>;
	loadingFallback: JSX.Element;
};

export function PetitionUpdatePageContextProvider({
	children,
	petitionSlugOrId,
	updateId,
	prefetchedData,
	errorHandler: ErrorHandler,
	loadingFallback,
}: PropsWithChildren<Props>): JSX.Element | null {
	const pageContextState = usePageContext(petitionSlugOrId, updateId, prefetchedData);

	if (isError(pageContextState)) {
		const { status, ...rest } = pageContextState;
		return <ErrorHandler context={rest} />;
	}
	if (!isLoaded(pageContextState)) return loadingFallback;

	const { pageData, pageFcm, pageFcmExperiments } = pageContextState;

	return (
		<PetitionUpdatePageDataContextProvider pageData={pageData}>
			<PetitionUpdatePageFcmContextProvider pageFcm={pageFcm}>
				<PetitionUpdatePageFcmExperimentsContextProvider pageFcmExperiments={pageFcmExperiments}>
					<PetitionUpdatePageStateContextProvider>
						<TrackingContext
							properties={{
								update_id: `update_${pageData.data.update.id}`,
								petition_id: `petition_${pageData.data.update.petition.id}`,
								kind: pageData.data.update.kind,
								promotable: pageData.data.update.petition.promotable,
								signed: pageData.data.petitionSigned,
							}}
						>
							{children}
						</TrackingContext>
					</PetitionUpdatePageStateContextProvider>
				</PetitionUpdatePageFcmExperimentsContextProvider>
			</PetitionUpdatePageFcmContextProvider>
		</PetitionUpdatePageDataContextProvider>
	);
}
