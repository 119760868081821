import { useMemo } from 'react';

import type { ShareActionsOptions } from 'src/app/shared/hooks/share';

import type { Channel } from './types';
import { useGetShareText } from './useGetShareText';
import { useGetShareTrackingData } from './useGetShareTrackingData';
import { useGetShareUrl } from './useGetShareUrl';

export function useShareOptions(): ShareActionsOptions<Channel> {
	const getUrl = useGetShareUrl();
	const getText = useGetShareText();
	const getTrackingData = useGetShareTrackingData();

	return useMemo(
		() => ({
			trackingEventName: 'share_update',
			getUrl,
			getText,
			getTrackingData,
		}),
		[getUrl, getText, getTrackingData],
	);
}
