import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';
import { useProcessedImageUrl } from 'src/app/shared/hooks/image';

type Result = ModelHookResult<{
	photo?: {
		url: string;
		mobileUrl: string;
	};
}>;

export function usePetitionUpdateMedia(): Result {
	const {
		data: { update },
	} = usePetitionUpdatePageData();

	const { photo } = update.__typename === 'FeaturedNewsPetitionUpdate' ? { photo: null } : update;

	const photoUrl = useProcessedImageUrl(photo?.petitionMedium);
	const photoMobileUrl = useProcessedImageUrl(photo?.petitionSmall);

	if (update.__typename === 'FeaturedNewsPetitionUpdate') {
		return {
			data: {
				photo: update.newsSource.photo
					? {
							url: update.newsSource.photo.url,
							mobileUrl: update.newsSource.photo.url,
						}
					: undefined,
			},
		};
	}

	return {
		data: {
			photo:
				photoUrl && photoMobileUrl
					? {
							url: photoUrl,
							mobileUrl: photoMobileUrl,
						}
					: undefined,
		},
	};
}
