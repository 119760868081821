import { createPrefetchableAsyncDataContext, pageDataErrorHandler } from 'src/app/shared/contexts/asyncData';

import { getPetitionUpdatePageData } from './api';
import type { PetitionUpdatePageDataErrorCode } from './types';

export const {
	Context: PetitionUpdatePageDataContext,
	Provider: PetitionUpdatePageDataContextProvider,
	useContext: usePetitionUpdatePageData,
	useAsyncData: usePetitionUpdatePageDataFromApi,
	prefetchAsyncData: prefetchPetitionUpdatePageDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'PetitionUpdatePageDataContext',
	dataProperty: 'pageData',
	getData: getPetitionUpdatePageData,
	getUniqueId: ({ petitionSlugOrId, updateId, userId }) => [petitionSlugOrId, updateId, userId || ''].join('|'),
	hookDeps: ({ petitionSlugOrId, updateId, userId }) => [petitionSlugOrId, updateId, userId],
	errorHandler: pageDataErrorHandler<PetitionUpdatePageDataErrorCode>(),
});
