import type { PetitionUpdatePageState } from './types';

export const mutations = {
	toggleBeforeSuggestedPetitions: (
		state: PetitionUpdatePageState,
		beforeSuggestedPetitions: boolean,
	): PetitionUpdatePageState => ({
		...state,
		beforeSuggestedPetitions,
	}),

	setPageError: (state: PetitionUpdatePageState, pageError: string): PetitionUpdatePageState => ({
		...state,
		pageError,
	}),
	clearPageError: (state: PetitionUpdatePageState): PetitionUpdatePageState => ({
		...state,
		pageError: undefined,
	}),
};
