import type { ComponentProps } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Link as RouterLink } from '@change/core/react/router';
import { Icon } from '@change/design-system/components/icon';
import { iconNavigateBefore, iconNavigateNext } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';
import { linkResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

import { useSiblingsNavigation } from './hooks/useSiblingsNavigation';

export function SiblingsNavigation(props: ComponentProps<typeof Box>) {
	const {
		data: { previous, next },
	} = useSiblingsNavigation();

	if (!previous && !next) return null;

	return (
		<Box {...props}>
			<Flex sx={{ justifyContent: 'space-between', gap: 16 }}>
				<Box sx={{ maxWidth: 300 }}>
					{previous && (
						<RouterLink to={previous.url} sx={{ ...linkResetStyles }}>
							<Flex>
								<Icon icon={iconNavigateBefore} mr={8} size="xxl" />
								<Box>
									<Text as="div" fontWeight="bold">
										<Translate value="fe.components.paging.previous" />
									</Text>
									<Text as="div" breakWord color="neutral-grey700">
										{previous.title}
									</Text>
								</Box>
							</Flex>
						</RouterLink>
					)}
				</Box>
				<Box sx={{ maxWidth: 300 }}>
					{next && (
						<RouterLink to={next.url} sx={{ ...linkResetStyles }}>
							<Flex sx={{ textAlign: 'right' }}>
								<Box>
									<Text as="div" fontWeight="bold">
										<Translate value="fe.components.paging.next" />
									</Text>
									<Text as="div" breakWord color="neutral-grey700">
										{next.title}
									</Text>
								</Box>
								<Icon icon={iconNavigateNext} ml={8} size="xxl" />
							</Flex>
						</RouterLink>
					)}
				</Box>
			</Flex>
		</Box>
	);
}
