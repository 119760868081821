import type { JSX } from 'react';

import {
	usePetitionUpdatePageData,
	usePetitionUpdatePageState,
} from 'src/app/pages/petitionUpdate/details/pageContext';
import { useSocialProofLabel } from 'src/app/shared/hooks/socialProof';

type Result = ModelHookResult<{
	hiddenOnMobile: boolean;
	socialProofLabel: string | JSX.Element | JSX.Element[];
}>;

export function useActionButton(): Result {
	const [{ beforeSuggestedPetitions }] = usePetitionUpdatePageState();
	const {
		data: {
			petitionCreatedLessThanADayAgo,
			update: {
				petition: {
					dailySignatureCount,
					dailySignatureCountLocalized,
					weeklySignatureCount,
					weeklySignatureCountLocalized,
				},
			},
		},
	} = usePetitionUpdatePageData();
	const socialProofLabel = useSocialProofLabel({
		dailySignatureCount,
		dailySignatureCountLocalized,
		weeklySignatureCount,
		weeklySignatureCountLocalized,
		createdLessThanOneDayAgo: petitionCreatedLessThanADayAgo,
	});

	return {
		data: {
			hiddenOnMobile: !beforeSuggestedPetitions,
			socialProofLabel,
		},
	};
}
