import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmExperimentsContext: PetitionUpdatePageFcmExperimentsContext,
	FcmExperimentsProvider: PetitionUpdatePageFcmExperimentsContextProvider,
	useFcmExperiments: usePetitionUpdatePageFcmExperiments,
	useAsyncFcmExperiments: useAsyncPetitionUpdatePageFcmExperiments,
	prefetchFcmExperiments: prefetchPetitionUpdatePageFcmExperiments,
} = createFcmExperimentsContext({
	name: 'PetitionUpdatePageFcmExperimentsContext',
	dataProperty: 'pageFcmExperiments',
	configs: {},
	prefetch: true,
});

export type PetitionUpdatePageFcmExperimentsState = ReturnType<typeof useAsyncPetitionUpdatePageFcmExperiments>;
export type PetitionUpdatePageFcmExperiments = LoadedStateData<PetitionUpdatePageFcmExperimentsState>;
