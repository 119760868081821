import {
	usePetitionUpdatePageData,
	usePetitionUpdatePageState,
} from 'src/app/pages/petitionUpdate/details/pageContext';

type Result = ModelHookResult<{
	message: string | undefined;
	showUnderReviewBanner: boolean;
}>;

export function usePageBanner(): Result {
	const [{ pageError }] = usePetitionUpdatePageState();
	const {
		data: {
			update: {
				petition: { status },
			},
		},
	} = usePetitionUpdatePageData();

	return {
		data: { message: pageError, showUnderReviewBanner: status === 'UNDER_REVIEW' },
	};
}
