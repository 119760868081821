import type { ComponentProps } from 'react';

import { Link } from '@change/design-system/components/actions';
import { Separator } from '@change/design-system/components/content';
import { Html } from '@change/design-system/html';
import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { EmbeddedMedia } from 'src/app/shared/components/embeddedMedia';

import { Actions } from './components/Actions';
import { ShareLinks } from './components/ShareLinks';
import { usePageBody } from './hooks/usePageBody';

export function PageBody(props: ComponentProps<typeof Box>) {
	const {
		data: { description, isSimpleUrlDescription, creationDate, embeddedMedia, link },
		actions: { handleLinkClick },
	} = usePageBody();

	return (
		<Box {...props}>
			{isSimpleUrlDescription ? (
				// older version of the update form allowed to simply input a url that would end up being the description
				<Box>
					<Text breakWord>
						<Text color="neutral-grey600" sx={{ whiteSpace: 'nowrap' }}>
							{creationDate} —&nbsp;
						</Text>
						<Link to={description}>{description}</Link>
					</Text>
				</Box>
			) : (
				<>
					<Box>
						<Text color="neutral-grey700">{creationDate}</Text>
					</Box>
					<Html as="div" html={description} mt={16} breakWord data-qa="petition-update-description" />
				</>
			)}
			{link && (
				<Box mt={16}>
					<Link to={link.url} target="_blank" onClick={handleLinkClick} data-qa="news-article-link">
						{link.label}
					</Link>
				</Box>
			)}
			{embeddedMedia?.html && (
				<EmbeddedMedia
					html={embeddedMedia.html}
					aspectRatio={embeddedMedia.aspectRatio || undefined}
					mt={16}
					sx={{
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&>iframe': {
							width: ['100%', 600],
						},
					}}
					data-qa="embedded-media"
				/>
			)}
			<Actions />
			<Separator my={24} />
			<ShareLinks />
		</Box>
	);
}
