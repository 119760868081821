import { Translate } from '@change/core/react/i18n';

import { ActionButton } from './components/ActionButton';
import { useActions } from './hooks/useActions';

export function Actions() {
	const {
		data: { promoteUrl, signUrl, respondUrl },
	} = useActions();

	if (!signUrl && !promoteUrl && !respondUrl) return null;

	if (respondUrl) {
		return (
			<ActionButton to={respondUrl} buttonName="dm-respond">
				<Translate value="fe.components.petition_decision_maker_respond.action" />
			</ActionButton>
		);
	}

	if (signUrl) {
		return (
			<ActionButton to={signUrl} socialProof buttonName="sign-petition">
				<Translate value="fe.components.petition_sign.action" />
			</ActionButton>
		);
	}

	if (promoteUrl) {
		return (
			<ActionButton to={promoteUrl} socialProof buttonName="next-step">
				<Translate value="fe.pages.petition_update.combo_action_button_label" />
			</ActionButton>
		);
	}
}
