import compact from 'lodash/compact';

import { useI18n } from '@change/core/react/i18n';

import { usePetitionUpdatePageData } from 'src/app/pages/petitionUpdate/details/pageContext';

export type PetitionUpdateAuthor = {
	type: 'staff' | 'user' | 'organization' | 'news';
	displayName: string;
	photoUrl?: string;
	location?: string;
};

type Result = ModelHookResult<{
	author: PetitionUpdateAuthor;
}>;

export function usePetitionUpdateByLine(): Result {
	const {
		data: { update },
	} = usePetitionUpdatePageData();
	const { translate, getCountry } = useI18n();

	if (update.__typename === 'FeaturedNewsPetitionUpdate') {
		const displayName = translate('fe.components.updates_feed.media_update.featured_in', {
			news_source: update.newsSource.name,
		});
		return {
			data: { author: { type: 'news', displayName } },
		};
	}

	if (update.__typename === 'StaffPetitionUpdate') {
		return { data: { author: { type: 'staff', displayName: translate('fe.pages.sponsors.change_team') } } };
	}

	if (update.petition.organization) {
		const {
			petition: {
				organization: { name, photo },
			},
		} = update;

		return {
			data: {
				author: {
					type: 'organization',
					displayName: name,
					photoUrl: !photo?.organizationSmall.processing ? photo?.organizationSmall.url : undefined,
				},
			},
		};
	}

	const {
		user: { displayName, photo, city, stateCode, country },
	} = update;

	return {
		data: {
			author: {
				type: 'user',
				displayName,
				photoUrl: !photo?.userSmall.processing ? photo?.userSmall.url : undefined,
				location:
					compact([city, stateCode, country ? getCountry(country.countryCode)?.name : undefined]).join(', ') ||
					undefined,
			},
		},
	};
}
